import '../assets/scss/site.scss';
import { title } from '../data/meta';

import React from 'react';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery, withPrefix } from 'gatsby';
import { Link } from '@reach/router';

import Navbar from './Navbar';
import Footer from './Footer';
import * as PropTypes from 'prop-types';

class Layout extends React.Component {
  componentDidMount () {
    //= ============================= ICON TOGGLER ANIMATION =========================
    const shadowClass = $('.mobile-sticky-header-overlay');
    const collapsibleDiv = $('.navbar-collapse');
    const toolbarToggle = $('.icon-toggle');

    $('.navbar-toggler').on('click', function () {
      $(this).css('visibility', 'hidden').toggleClass('clicked');
      $('.navbar-brand').toggleClass('shade');
      toolbarToggle.toggleClass('active');
      shadowClass.toggleClass('active');
      collapsibleDiv.toggleClass('show');
      $(this).css('visibility', 'visible');
    });

    $('.navbar a.dropdown-toggle').on('click', function () {
      const elmnt = $(this).parent().parent();
      if (!elmnt.hasClass('navbar-nav')) {
        const li = $(this).parent();
        const heightParent = parseInt(elmnt.css('height').replace('px', ''), 10) / 2;
        const widthParent = parseInt(elmnt.css('width').replace('px', ''), 10) - 10;

        if (!li.hasClass('show')) { li.addClass('show'); } else { li.removeClass('show'); }
        $(this).next().css('top', heightParent + 'px');
        $(this).next().css('left', widthParent + 'px');

        return false;
      }
    });
  }

  render () {
    const { pageWrapper, children } = this.props;

    return (
      <StaticQuery
        query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
        render={data => {
          return (
            <>
              <Helmet bodyAttributes={{
                id: 'body',
                class: 'home-classic boxed-menu',
              }}
              >
                <html lang="en" />
                <title>{data.site.siteMetadata.title}</title>
                <meta name="theme-color" content="#fff" />
                <meta name="description" content={data.site.siteMetadata.description} />
                <meta name="keywords" content={data.site.siteMetadata.keywords} />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content="Onsite" />
                <meta property="og:url" content="/" />
                <meta
                  property="og:image"
                  content={`${withPrefix('/')}img/og-image.jpg`}
                />

                <script
                  src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.slim.min.js"
                  integrity="sha256-pasqAKBDmFT4eHoN2ndd6lN370kFiGUFyTiUHWhU7k8=" crossOrigin="anonymous"
                />
              </Helmet>
              <div>
                <div className="mobile-sticky-header-overlay" />
                {/* TOPBAR */}
                <div className="topbar">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-9">
                        <div className="topbar-content">
                          <i className="fa fa-phone" aria-hidden="true" />
                          <a href="tel:262-206-1655">(262) 206-1655</a>
                        </div>
                        <div className="topbar-content">
                          <i className="fa fa-envelope" aria-hidden="true" />
                          <a href="mailto:sales@onsitemachinecompany.com">sales@onsitemachinecompany.com </a>
                        </div>
                        <div className="topbar-content">
                          <i className="fa fa-map-marker" aria-hidden="true" />
                          <span>Kenosha, WI </span>
                        </div>
                      </div>
                      <div className="col-sm-3 text-right">
                        <Link to="/quote" className="btn btn-primary btn-default bold">Get a Quote</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <header id="pageTop" className="header">
                  <Navbar />
                </header>
                <div className={`main-wrapper ${pageWrapper || ''}`}>
                  {children}
                  <Footer />
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  pageWrapper: PropTypes.any,
  children: PropTypes.any,
};

export default Layout;
