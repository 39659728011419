import React from 'react';
import { Link } from '@reach/router';
import NavItem from './NavItem';
import { withPrefix } from 'gatsby';

class Navbar extends React.Component {
  render () {
    return (
      <nav className="navbar navbar-expand-md main-nav bg-dark">
        <div className="container">
          <button className="navbar-toggler navbar-toggler-right">
            <span className="burger-menu icon-toggle"><i className="fa fa-bars" /></span>
          </button>
          <Link className="navbar-brand" to="/">
            <img src={`${withPrefix('/')}img/logo-dark-sm.jpg`} alt="Logo" />
          </Link>
          <div className="navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <NavItem pathToLookFor="/" exact>
                <Link className="nav-link" to="/">Home</Link>
              </NavItem>
              <NavItem pathToLookFor="/contact">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </NavItem>
            </ul>
          </div>

        </div>
        <div className="container d-md-none">
          <div>
            <a className="text-light" href="tel:262-206-1655">(262) 206-1655</a>
          </div>
          <div className="text-light">
            Kenosha, WI
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
