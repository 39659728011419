import React from 'react';
import { withPrefix } from 'gatsby';
import { Link } from '@reach/router';

class Footer extends React.Component {
  render () {
    return (
      <footer className="footer footer-classic bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-text">
                <Link to="/">
                  <img className="img-fluid" src={`${withPrefix('/')}img/logo-dark.jpg`} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="location">
                <h3>Location</h3>
                <ul>
                  <li>Onsite Machine Company Is On Site 24/7</li>
                  <li><i className="fa fa-phone" /> <a href="tel:262-206-1655">(262) 206-1655</a></li>
                  <li><i className="fa fa-envelope" /> <a href="mailto:sales@onsitemachinecompany.com"> sales@onsitemachinecompany.com </a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="links">
                <h3>Links</h3>
                <ul className>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/quote">Request a Quote</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright">
            <hr />
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <div className="copyRight_text text-center">
                  <p> © {new Date().getFullYear()} Powered by <a target="_blank" href="https://cardinalcompass.com/" rel="noopener noreferrer">Cardinal Marketing Group</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
