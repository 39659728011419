import React from 'react'
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';
import { Location } from '@reach/router'

const NavItem = ({ pathToLookFor, exact, isDropdown, children }) => {
    return (
        <Location>
            {({location}) => {

                const isActive = exact ? location.pathname === pathToLookFor : _.includes(location.pathname, pathToLookFor);

                return (
                    <li className={classnames({'nav-item': true, 'dropdown': isDropdown, 'active': isActive})}>
                        {children}
                    </li>);
            }}
        </Location>
    );
};

NavItem.propTypes = {
    pathToLookFor: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    isDropdown: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default NavItem